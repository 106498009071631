import "please-wait/build/please-wait.css"
import "spinkit/css/spinkit.css"
import {pleaseWait} from "please-wait";
import $ from "jquery";

let logo = require("../img/logo.png");

let loader = pleaseWait({
    logo: logo,
    backgroundColor: '#fae4bd',
    loadingHtml: `
        <div class="sk-circle">
            <div class="sk-circle1 sk-child"></div>
            <div class="sk-circle2 sk-child"></div>
            <div class="sk-circle3 sk-child"></div>
            <div class="sk-circle4 sk-child"></div>
            <div class="sk-circle5 sk-child"></div>
            <div class="sk-circle6 sk-child"></div>
            <div class="sk-circle7 sk-child"></div>
            <div class="sk-circle8 sk-child"></div>
            <div class="sk-circle9 sk-child"></div>
            <div class="sk-circle10 sk-child"></div>
            <div class="sk-circle11 sk-child"></div>
            <div class="sk-circle12 sk-child"></div>
        </div>`
});

$(function () {
    loader.finish();
});