import bootbox from "bootbox";

$(document).on("submit", "[name=birth-list-search-form]",
    function (e) {
        e.preventDefault();
        const $form = $(this);
        const $inputCode = $form.find("[name=code]");
        const code = $inputCode.val();
        console.log(code);
        $.get($form.attr("action"),
            {"code": code.trim()}
        ).then(function (res) {
            window.location = res.url;
        }).catch(
            function (err) {
                if (err.status === 404) {
                    bootbox.alert("Nessuna lista nascita presente con il codice inserito.")
                }
            }
        );
    });