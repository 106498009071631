import SmoothScroll from "smooth-scroll";


let scroll = new SmoothScroll('a[href*="#"]',
    {
        offset: (anchor, toggle) => {
            let el = document.querySelector("#main-nav");
            console.log(el.clientHeight)
            return el.clientHeight;
        }
    }
);


// $(function () {
    setTimeout(function () {
        if (location.hash) {
            /* we need to scroll to the top of the window first, because the browser will always jump to the anchor first before JavaScript is ready, thanks Stack Overflow: http://stackoverflow.com/a/3659116 */
            window.scrollTo(0, 0);
            let target = location.hash.split('#');
            let el = document.querySelector('#' + target[1]);
            scroll.animateScroll(el);
        }
    }, 1);
// });