import "bootstrap";
import {initCarousel} from "./carousel";
import "./loader";
import "./scroll";
import "./lightbox";
import "./birth_lists";

export function initJs() {
    initCarousel();
}

// first run
initJs();
